<!--
 * @Author: goodLiang
 * @Date: 2023-02-16 15:15:59
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-18 10:23:22
 * @FilePath: /business-cms/src/layout/components/HeaderBar.vue
-->
<template>
  <div class="head">
    <div class="head-main">
      <div class="head-main__left">
        <img src="@/assets/gd_logo.png" class="logo" alt="" />
        <div class="title">商家工作台</div>
      </div>
      <div class="head-main__right" v-if="userInfo.account">
        <el-dropdown trigger="click">
          <div class="user">
            <div class="user-avatar">
              <img
                src="@/assets/user_head.png"
                alt=""
                srcset=""
                class="user-icon"
              />
            </div>
            <div class="user-info">
              <div class="user-info__name">{{ userInfo.account }}</div>
              <div class="user-info__desc" v-if="userInfo.shop_name">
                {{ userInfo.shop_name }}
              </div>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu class="user-dropdown">
              <router-link
                :to="`/shop/detail/${userInfo.id}`"
                v-if="userInfo.apply_state === 3"
              >
                <el-dropdown-item>店铺信息</el-dropdown-item>
              </router-link>
              <el-dropdown-item divided @click="logout">
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
const store = useStore()
const userInfo = computed(() => store.getters.userInfo)
const logout = () => {
  store.dispatch('user/logout')
  ElMessage.success('退出成功')
}
</script>

<style lang="scss" scoped>
.head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;

  &-main {
    padding: 0 30px;
    height: 84px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;

      .logo {
        width: 154px;
        height: 36px;
        margin-right: 14px;
      }

      .title {
        font-size: 28px;
        font-weight: 400;
        padding-left: 14px;
        border-left: 2px solid #f3f4f7;
      }
    }

    &__right {
      display: flex;
      align-items: center;
    }
  }

  .user {
    display: flex;
    align-items: center;
    cursor: pointer;
    &-icon {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }

    &-info {
      &__name {
        font-size: 16px;
        font-weight: bold;
      }
      &__desc {
        margin-top: 5px;
        font-size: 12px;
        color: #484d52;
      }
    }

    &-logout {
      font-weight: 500;
      color: #a5afb8;
    }
  }
}
</style>
