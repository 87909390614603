/*
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-07-15 13:34:20
 * @FilePath: /business-cms/src/router/index.js
 */
import { createRouter, createWebHistory } from 'vue-router'
import layout from '@/layout'
import headView from '@/layout/view1.vue'
import manageRouter from './modules/manage'
import shopRouter from './modules/shop'
import store from '@/store'

/**
 * 私有路由表
 */
export const privateRoutes = [manageRouter, shopRouter]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index')
  },
  {
    path: '/weixin',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/weixin/share')
  },
  {
    path: '/enter',
    component: headView,
    redirect: '/enter',
    children: [
      {
        path: '/enter',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/enter/index')
      }
    ]
  },
  {
    path: '/',
    component: layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/home/index'),
        meta: {
          title: '首页',
          icon: 'personnel'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      }
    ]
  }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach((menu) => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: publicRoutes
})

export default router
