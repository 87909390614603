/*
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-10 18:23:27
 * @FilePath: /vue3-template/src/store/modules/user.js
 */
import { loginUser, registerUser, getUserInfo } from '@/api/user'
import md5 from 'md5'
import { getItem, setItem, removeAllItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
import router, { resetRouter } from '@/router'
import { setTimeStamp } from '@/utils/auth'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {}
  }),
  mutations: {
    /**
     * 保存 token
     */
    setToken(state, newToken) {
      state.token = newToken
      setItem(TOKEN, newToken)
    },
    /**
     * 保存用户信息
     */
    setUserInfo(state, newInfo) {
      state.userInfo = newInfo
    }
  },
  actions: {
    /**
     * 注册
     */
    async register(context, payload) {
      const { password } = payload
      return await registerUser({
        ...payload,
        password: password
      })
    },
    /**
     * 登录
     */
    login(context, userInfo) {
      const { account, password } = userInfo
      return new Promise((resolve, reject) => {
        loginUser({
          account,
          password: md5('syslogin@' + account.substring(5) + '@' + password)
        })
          .then((data) => {
            this.commit('user/setToken', data.token)
            // 保存登录时间
            setTimeStamp()
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 获取用户信息
     */
    async getUserInfo(context) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((data) => {
            context.commit('setUserInfo', data)
            resolve(data)
          })
          .catch((err) => {
            console.log('err', err)
            reject(err)
          })
      })
    },
    /**
     * 退出登录
     */
    logout() {
      resetRouter()
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      this.commit('app/initTagsViewList')
      removeAllItem()
      router.push('/login')
      location.reload()
    }
  }
}
