/*
 * @Author: goodLiang
 * @Date: 2023-02-15 16:36:17
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-01-03 11:29:14
 * @FilePath: /business-cms/src/api/user.js
 */
import request from '@/utils/request'

/**
 * 登录
 */
export const loginUser = (data) => {
  return request({
    url: '/admin/popSeller/loginPopSeller',
    method: 'POST',
    data
  })
}

/**
 * 注册
 */
export const registerUser = (data) => {
  return request({
    url: '/admin/popSeller/registerPopSeller',
    method: 'POST',
    data
  })
}

/**
 * 找回密码
 */
export const updatePopSellerPassWord = (data) => {
  return request({
    url: '/admin/popSeller/updatePopSellerPassWord',
    method: 'POST',
    data
  })
}

/**
 * 短信
 */

export const smsCenter = (data) => {
  return request({
    url: '/admin/popSeller/sendSecurityCodeByRegisterPopSeller',
    method: 'POST',
    data
  })
}

/**
 * 找回密码短信
 */

export const smsCenterFind = (data) => {
  return request({
    url: '/admin/popSeller/sendSecurityCodeByUpdatePopSellerPassWord',
    method: 'POST',
    data
  })
}

/**
 *  获取用户信息
 */

export const getUserInfo = (data) => {
  return request({
    url: '/admin/popSeller/queryPopSellerInfo',
    method: 'POST',
    data
  })
}

/**
 * @description 上传文件
 */
export const uploadFile = '/gdyx/file/system/file/uploadFile'
export const uploadFileYb = '/gdyx/file/system/yeepay/uploadQualificationFile'

/**
 *  获取省市区
 */

export const getProvince = (data) => {
  return request({
    url: '/pay/account/queryLevelOneAddress',
    method: 'POST',
    data
  })
}
export const getCity = (data) => {
  return request({
    url: '/pay/account/queryLevelTwoAddress',
    method: 'POST',
    data
  })
}
export const getDistrict = (data) => {
  return request({
    url: '/pay/account/queryLevelThreeAddress',
    method: 'POST',
    data
  })
}
