<!--
 * @Author: goodLiang
 * @Date: 2023-12-05 11:15:33
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-13 11:02:33
 * @FilePath: /vue3-template/src/components/CustomCard/index.vue
-->
<template>
  <div class="custom-card-wrap" :class="{ 'custom-card-shadow-wrap': shadow }">
    <div v-if="$slots.cardTitle || $props.title" class="custom-card-title-wrap">
      <slot name="cardTitle">{{ $props.title }}</slot>
    </div>
    <div class="custom-card-operation-wrap" v-if="$slots.cardOperation">
      <slot name="cardOperation" />
    </div>
    <div class="custom-card-content-wrap">
      <slot></slot>
    </div>
    <div
      class="custom-card-operation-bottom-wrap"
      v-if="$slots.bottomOperation"
    >
      <slot name="bottomOperation"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    shadow: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.custom-card-wrap {
  margin-bottom: 10px;

  .custom-card-title-wrap {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #001426;
    margin-bottom: 20px;
  }

  .custom-card-operation-wrap {
    width: 100%;
    margin-bottom: 12px;
  }

  .custom-card-operation-bottom-wrap {
    width: 100%;
    // height: 40px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  :deep {
    .down-box {
      text-align: center;
      margin: 20px 0;
    }
  }
}

.custom-card-shadow-wrap {
  background: #ffffff;
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px 30px;
}
</style>
