<template>
  <el-col
    class="grid-item-wrap"
    :class="`grid-item-${$props.form}-wrap`"
    :sm="$props.sm"
    :md="$props.md"
    :lg="lgValue"
    :xl="xlValue"
  >
    <div class="grid-item-container-wrap">
      <slot>
        <div
          class="grid-item-label-wrap"
          :style="{ width: `${$props.labelWidth}px`, textAlign: labelPosition }"
        >
          <slot name="label">{{ $props.label }}</slot>
        </div>
        <div class="grid-item-content-wrap">
          <slot name="content">{{ $props.content }}</slot>
        </div>
      </slot>
    </div>
  </el-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GridItem',
  props: {
    labelPosition: {
      type: String,
      default: 'left'
    },
    form: {
      type: String,
      default: ''
    },
    label: {
      type: [String, Number, null],
      default: () => ''
    },
    labelWidth: {
      type: Number,
      default: 100
    },
    content: {
      type: [String, Number, null],
      default: () => ''
    },
    sm: {
      type: [Number, Object],
      default: 12
    },
    md: {
      type: [Number, Object],
      default: 12
    },
    lg: {
      type: [Number, Object]
    },
    xl: {
      type: [Number, Object]
    }
  },
  computed: {
    ...mapGetters(['sidebar']),
    lgValue() {
      return this.$props.lg ? this.$props.lg : 8
    },
    xlValue() {
      return this.$props.xl ? this.$props.xl : 6
    }
  }
}
</script>

<style scoped lang="scss">
.grid-item-wrap {
  //width: 100%;
  //display: flex;
  // margin-bottom: 10px;
  margin-bottom: 6px;
  font-size: 14px;

  .grid-item-container-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .grid-item-label-wrap {
      padding: 0 12px 0 0;
      font-size: 14px;
      color: #323233;

      font-weight: 500;
    }

    .grid-item-content-wrap {
      flex: 2;
      min-height: 40px;
      display: flex;
      align-items: center;
      padding: 0 16px;
    }

    :deep {
      .el-form-item {
        width: 100%;
        display: flex;
        align-items: center;

        .el-form-item__label {
          padding-left: 10px;
        }
        .el-form-item__content {
          flex: 2;
          margin-left: 0 !important;
        }
        .el-select {
          display: block;
          width: 100%;
        }
      }

      .el-date-editor--datetimerange.el-input__inner,
      .el-range-editor.el-input__inner {
        width: 100%;
      }

      .el-date-editor--daterange.el-input__inner {
        width: 100%;
      }

      .el-cascader {
        display: block;
      }

      .el-button {
        //padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .el-row {
        display: flex;
        align-items: center;
      }
    }
  }
}

.grid-item-detail-wrap {
  .grid-item-label-wrap {
    color: #323233;
    padding-left: 10px !important;
  }

  .grid-item-content-wrap {
    background: #f7fafc;
    border-radius: 2px;
    color: #001426;
  }
}
</style>
