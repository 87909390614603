/*
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-13 13:51:56
 * @FilePath: /vue3-template/src/main.js
 */
import { createApp } from 'vue'
// i18n （PS：导入放到 APP.vue 导入之前，因为后面我们会在 app.vue 中使用国际化内容）

import App from './App.vue'
import store from './store'
import router from './router'
import { to } from 'await-to-js'
import CustomCard from '@/components/CustomCard'
import FoldPanel from '@/components/FoldPanel'
import GridItem from '@/components/GridItem'
import SubHead from '@/components/SubHead'

import installElementPlus from './plugins/element'
// 导入 svgIcon
import installIcons from '@/icons'
// filter
import installFilter from '@/filters'
import installDirective from '@/directives'
// 导入全局样式
import './styles/index.scss'
// 导入权限控制模块
import './permission'

const app = createApp(App)

installElementPlus(app)
installIcons(app)
installFilter(app)
installDirective(app)

app.component('CustomCard', CustomCard)
app.component('FoldPanel', FoldPanel)
app.component('GridItem', GridItem)
app.component('SubHead', SubHead)

app.config.globalProperties.$await = to

app.use(store).use(router).mount('#app')
