/*
 * @Author: goodLiang
 * @Date: 2023-12-04 16:37:08
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-04 19:19:29
 * @FilePath: /vue3-template/src/utils/uuid.js
 */
export function uuid() {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  const uuid = s.join('')
  return uuid
}

// class uuidSingel {
//   constructor() {
//     this.deviceId = this.uuid();
//   }
//   uuid() {
//     let s = [];
//     let hexDigits = "0123456789abcdef";
//     for (let i = 0; i < 36; i++) {
//       s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
//     }
//     s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
//     s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
//     s[8] = s[13] = s[18] = s[23] = "-";

//     let uuid = s.join("");
//     return uuid;
//   }
// }

// export default new uuidSingel();
