/*
 * @Author: goodLiang
 * @Date: 2023-12-04 16:52:39
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-06 14:32:19
 * @FilePath: /vue3-template/src/router/modules/shop.js
 */
import layout from '@/layout'

export default {
  path: '/shop',
  component: layout,
  redirect: '/shop/index',
  name: 'shop',
  meta: { title: '店铺设置', icon: 'article' },
  children: [
    {
      path: '/shop/index',
      component: () => import('@/views/shop/index'),
      meta: {
        title: '店铺管理',
        icon: 'article-ranking'
      }
    },
    {
      path: '/shop/detail/:id',
      component: () => import('@/views/shop/detail'),
      meta: {
        title: '店铺详情'
      }
    },
    {
      path: '/shop/property/:id',
      component: () => import('@/views/shop/property'),
      meta: {
        title: '资产总览'
      }
    },
    {
      path: '/shop/bank-list/:id',
      component: () => import('@/views/shop/bank-list'),
      meta: {
        title: '银行卡列表'
      }
    },
    {
      path: '/shop/cash-details/:id',
      component: () => import('@/views/shop/cash-details'),
      meta: {
        title: '提现明细'
      }
    },
    {
      path: '/shop/settlement-details/:id',
      component: () => import('@/views/shop/settlement-details'),
      meta: {
        title: '结算明细'
      }
    }
  ]
}
