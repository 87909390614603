/*
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-10 18:19:45
 * @FilePath: /vue3-template/src/store/index.js
 */
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import user from './modules/user'
import app from './modules/app'
import theme from './modules/theme'
import permission from './modules/permission'
import dial from './modules/dial' // 抽奖数据

export default createStore({
  getters,
  modules: {
    user,
    app,
    theme,
    permission,
    dial
  },
  plugins: [
    createPersistedState({
      // 保存到 localStorage 中的 key
      key: 'gede',
      // 需要保存的模块
      paths: ['dial']
    })
  ]
})
