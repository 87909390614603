/*
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-07-15 13:34:29
 * @FilePath: /business-cms/src/permission.js
 */
import router from './router'
import store from './store'

// 白名单
const whiteList = ['/login', '/weixin']
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  // if (store.state.user.token) {
  // 快捷访问
  if (store.getters.token) {
    if (to.path === '/login') {
      next('/')
    } else {
      // 判断用户资料是否获取
      // 若不存在用户信息，则需要获取用户信息
      if (!store.getters.hasUserInfo) {
        // 触发获取用户信息的 action，并获取用户当前权限
        const res = await store.dispatch('user/getUserInfo')
        // 处理用户权限，筛选出需要添加的权限
        if (res.apply_state === 3) {
          const filterRoutes = await store.dispatch('permission/filterRoutes')
          // 利用 addRoute 循环添加
          filterRoutes.forEach((item) => {
            router.addRoute(item)
          })
        } else {
          return next('/enter')
        }
        // 添加完动态路由之后，需要在进行一次主动跳转
        return next(to.fullPath)
      }
      next()
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
