/*
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-06 13:47:34
 * @FilePath: /vue3-template/src/filters/index.js
 */
import dayjs from 'dayjs'
import rt from 'dayjs/plugin/relativeTime'
// 语言包
import 'dayjs/locale/zh-cn'
import store from '@/store'

import { fen2yuan } from '@/utils/util'

const dateFilter = (val, format = 'YYYY-MM-DD') => {
  if (!isNaN(val)) {
    val = parseInt(val)
  }

  return dayjs(val).format(format)
}

// 加载相对时间插件
dayjs.extend(rt)
function relativeTime(val) {
  if (!isNaN(val)) {
    val = parseInt(val)
  }
  return dayjs()
    .locale(store.getters.language === 'zh' ? 'zh-cn' : 'en')
    .to(dayjs(val))
}

const formatPrice = (value) => {
  if (typeof value === 'number' || typeof value === 'string') {
    const result = fen2yuan(value)
    return result
  } else if (value === null) {
    return '0.00'
  } else {
    return value
  }
}

/**
 * @description: 价格千分位分隔符 123456->123,456.00  123456.789->123,456.789
 * @param {number|string} value
 * @return {string|value}
 */
const toThousands = (value) => {
  if (typeof value === 'number' || typeof value === 'string') {
    let s = value /** 获取小数型数据**/
    s += ''
    if (s.indexOf('.') === -1) {
      s += '.0'
    } /** 如果没有小数点，在后面补个小数点和0**/
    if (/\.\d$/.test(s)) s += '0' /** 正则判断**/
    while (/\d{4}(\.|,)/.test(s)) {
      /** 符合条件则进行替换**/
      s = s.replace(/(\d)(\d{3}(\.|,))/, '$1,$2')
    } /** 每隔3位添加一个**/
    return s
  } else if (value === null) {
    return '0.00'
  } else {
    return value
  }
}

export default (app) => {
  app.config.globalProperties.$filters = {
    dateFilter,
    relativeTime,
    formatPrice,
    toThousands
  }
}
