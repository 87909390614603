<template>
  <div class="fold-panel-wrap">
    <!--      <div class="fold-panel-content-box-wrap" :style = "computedStyle">-->
    <div
      class="fold-panel-content-box-wrap"
      :style="`${
        $props.fold && line > 1 ? `height: ${computedHeight}px` : 'height: 100%'
      }`"
    >
      <div class="fold-panel-content-wrap" :class="[targetClass]">
        <slot />
      </div>
    </div>
    <div class="fold-panel-operation-wrap">
      <!-- <el-divider v-if="$props.fold && ((Number(maxLine) - defaultLine >= 1) || ($props.minHeight && $props.maxHeight))"> -->
      <div
        v-if="
          $props.fold &&
          (Number(maxLine) - defaultLine >= 1 ||
            ($props.minHeight && $props.maxHeight))
        "
        style="padding-left: 10px; width: 100px; display: flex; cursor: pointer"
        @click="handleClick"
      >
        <div
          style="margin-right: 8px; cursor: pointer; font-size: 14px"
          class="i-text-primary"
        >
          {{ !status ? '收起' : '展开' }}
        </div>
        <i
          class="el-icon-arrow-right icon-wrap i-text-primary"
          :class="!status ? 'icon-open-wrap' : 'icon-close-wrap'"
          style="cursor: pointer"
        />
      </div>
      <!-- </el-divider> -->
      <div class="button-wrap" v-if="$slots.operation">
        <slot name="operation" />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'

export default {
  name: 'FoldPanel',
  props: {
    maxHeightOffset: {
      type: Number,
      default: 0
    },
    itemHeight: {
      type: Number,
      default: 55
    },
    minHeight: {
      type: Number,
      default: 0
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    fold: {
      type: Boolean,
      default: true
    }
  },
  // computed: {
  //   ...mapGetters(['sidebar', 'tabsStatus'])
  // },
  // watch: {
  //   'sidebar.opened'() {
  //     this.$nextTick(() => {
  //       this.Initialize()
  //     })
  //   },
  //   tabsStatus() {
  //     this.$nextTick(() => {
  //       this.Initialize()
  //     })
  //   }
  // },
  data() {
    return {
      defaultLine: 2,
      status: true,
      computedHeight: 0,
      maxLine: 0,
      line: 1,
      targetClass: `fold-panel-content-wrap${new Date().getTime()}`
    }
  },
  methods: {
    Initialize() {
      nextTick(() => {
        const { maxHeight, minHeight, itemHeight, maxHeightOffset } =
          this.$props
        if (maxHeight && minHeight) {
          this.computedHeight = this.status
            ? minHeight
            : maxHeight + maxHeightOffset
          return
        }
        const TargetRef = document
          .querySelector(`.${this.targetClass}`)
          ?.getBoundingClientRect()
        if (!TargetRef || !itemHeight) return
        this.maxLine = Number((TargetRef?.height / itemHeight).toFixed(2))
        const difference = Math.floor(this.maxLine) - this.defaultLine
        if (difference <= 0) {
          this.status = true
          this.line = 1
        } else {
          this.line = this.status ? this.defaultLine : this.maxLine
        }
        const height = itemHeight * this.line
        // console.log(TargetRef, this.maxLine, Math.floor(this.maxLine), height, this.status);
        this.computedHeight =
          !this.status && maxHeightOffset ? height + maxHeightOffset : height
      })
    },
    handleClick() {
      this.status = !this.status
      this.Initialize()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.Initialize()
    })
    window.onresize = this.Initialize
  }
}
</script>

<style scoped lang="scss">
.fold-panel-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .fold-panel-content-box-wrap {
    width: 100%;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
  }

  .fold-panel-operation-wrap {
    display: flex;
    align-items: center;
    // flex: 2;
    background: #ffffff;
    position: relative;
    // padding: 0 20px;

    .icon-open-wrap {
      transform: rotate(-90deg);
    }

    .icon-close-wrap {
      transform: rotate(90deg);
    }

    .button-wrap {
      width: 100%;
      min-height: 50px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
