<!--
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-11 11:40:52
 * @FilePath: /vue3-template/src/layout/components/Sidebar/index.vue
-->
<template>
  <div class="">
    <el-scrollbar>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu'
import {} from 'vue'

const logoHeight = 44
</script>

<style lang="scss" scoped>
.logo-container {
  height: v-bind(logoHeight) + 'px';
  padding: 10px 0 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-title {
    margin-left: 10px;
    color: #fff;
    font-weight: 600;
    line-height: 50px;
    font-size: 16px;
    white-space: nowrap;
  }
}
</style>
