/*
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-06 19:31:44
 * @FilePath: /vue3-template/src/utils/request.js
 */
import axios from 'axios'
import store from '@/store'
import { uuid } from '@/utils/uuid'
import md5 from 'md5'
import { ElMessage, ElLoading } from 'element-plus'

let loading
const startLoading = () => {
  loading = ElLoading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0,0,0,.6)'
  })
}
const endLoading = () => {
  loading.close()
}
let needLoading = 0
export function showLoading() {
  if (needLoading === 0) {
    startLoading()
  }
  needLoading++
}
export function hideLoading() {
  if (needLoading <= 0) return
  needLoading--
  if (needLoading === 0) {
    endLoading()
  }
}

const timeStamp = Date.now()
const urlReg = /\/([A-Za-z0-9-_]+)(?=\?|$)/
const appId = 10000001

const service = axios.create({
  baseURL: '/gdyx', // url = base url + request url
  withCredentials: false, // 携带cookie, 不支持跨域
  responseType: '',
  timeout: 30000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    showLoading()
    if (config.method === 'post') {
      const transactionType = config.url.match(urlReg)[1]
      const header = {
        app_id: appId,
        time_stamp: timeStamp,
        transaction_type: transactionType,
        token: store.getters.token || '',
        resources_id: '',
        message_id: uuid()
      }

      config.data = {
        header,
        body: {
          ...config.data
        }
      }

      config.params = {
        app_id: appId,
        transaction_type: transactionType,
        sign: md5('fe6bd6d1' + JSON.stringify(config.data) + timeStamp),
        ...config.params
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    hideLoading()
    if (response.data instanceof Blob) {
      return response.data
    }
    const { header, body } = response.data
    if (header?.res_code === 0) {
      return body
    }
    if (header?.res_code === 460) {
      setTimeout(() => {
        store.dispatch('user/logout')
      }, 1000)
    }
    ElMessage({
      message: header?.message || 'Error',
      type: 'error'
    })
    return Promise.reject(header)
  },
  (error) => {
    hideLoading()
    ElMessage({
      message: error.message || 'Error',
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default service
