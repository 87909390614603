import { ElMessage } from 'element-plus'
import moment from 'moment'
import { Decimal } from 'decimal.js'
/**
 * @description 获取当前时间
 */
export function getNowTime(time) {
  let date = new Date()
  if (time) date = new Date(time)
  const y = date.getFullYear()
  const m =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
  const d = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
  const h = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
  const i = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
  const s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
  return y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s
}

/**
 * @description: 不为空的数据添加到请求体中
 * @param {Object}  postParams   过滤完成的数据
 * @param {Object}  obj   需要过滤的数据
 * @return {type} 该方法会改变postParams
 */
export function appendPostData(postParams, obj) {
  for (const i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      if (obj[i] !== '') {
        postParams[i] = obj[i]
      }
    }
  }
  return postParams
}

/**
 * 四舍五入保留2位小数（不够位数，则用0替补）
 */
export function toDecimal2(num) {
  var result = parseFloat(num)
  if (isNaN(result)) {
    alert('传递参数错误，请检查！')
    return false
  }
  result = Math.round(num * 100) / 100
  var s_x = result.toString()
  console.log(s_x + '-------')
  var pos_decimal = s_x.indexOf('.')
  if (pos_decimal < 0) {
    pos_decimal = s_x.length
    s_x += '.'
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += '0'
  }
  return s_x
}

// function accDiv(arg1,arg2){
//   var t1=0,t2=0,r1,r2;
//   try{t1=arg1.toString().split(".")[1].length}catch(e){}
//   try{t2=arg2.toString().split(".")[1].length}catch(e){}
//   with(Math){
//   r1=Number(arg1.toString().replace(".",""))
//   r2=Number(arg2.toString().replace(".",""))
//   return accMul((r1/r2),pow(10,t2-t1));
//   }
//   }
// 乘法
function accMul(arg1, arg2) {
  var m = 0
  var s1 = arg1.toString()
  var s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {}
  try {
    m += s2.split('.')[1].length
  } catch (e) {}
  return (
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
    Math.pow(10, m)
  )
}
/**
  以上是网上copy出来的
  以下是我的
**/
export function toFixedTwo(n) {
  console.log(n)
  if (isNaN(n)) {
    console.error('请输入正确的数值')
    return false
  }
  return (Math.round(accMul(n, 100)) / 100).toFixed(2)
}
/**
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
export function renderTime(date, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) {
    return ''
  }
  return moment(date).format(format)
  // var dateee = new Date(date).toJSON();
  // return new Date(+new Date(dateee) + 8 * 3600 * 1000)
  //   .toISOString()
  //   .replace(/T/g, " ")
  //   .replace(/\.[\d]{3}Z/, "");
}
//  导出
export function fileExportProcessing(file_extension, data) {
  // 数据导出有问题，返回参数会有header
  if (data.header) {
    const message = data.header.message || '导出失败！'
    ElMessage(message)
    return
  }
  ElMessage.success('导出成功')
  const blob = new Blob([data], {
    type: 'application/octet-stream'
  }) // 转化为blob对象
  const time = new Date().getTime()
  const timeStr = renderTime(time)
  const filename = timeStr + file_extension // 判断是否使用默认文件名
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var blobURL = window.URL.createObjectURL(blob) // 将blob对象转为一个URL
    var tempLink = document.createElement('a') // 创建一个a标签
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename) // 给a标签添加下载属性
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink) // 将a标签添加到body当中
    tempLink.click() // 启动下载
    document.body.removeChild(tempLink) // 下载完毕删除a标签
    window.URL.revokeObjectURL(blobURL)
  }
}
/**
 * @description: 处理数字
 * @param {Number} value 数值
 * @param {Number} precision 数值精度
 * @return {*}
 */
export function formatNumber(value, precision) {
  if (!value) {
    return ''
  } else if (precision === 0) {
    return Math[value > 0 ? 'floor' : 'ceil'](value)
  }
  return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
}

/**
 * @description: 协议
 * @param {string}
 * @return {*}
 */
export function agreementTemp(title, value) {
  return `<!DOCTYPE html>
  <html>
  <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover">
    <link href="https://cdn.quilljs.com/1.3.7/quill.core.css" rel="stylesheet" />
    <link href="https://cdn.quilljs.com/1.3.7/quill.snow.css" rel="stylesheet" />
    <link href="https://cdn.quilljs.com/1.3.7/quill.bubble.css" rel="stylesheet" />
    <title>${title}</title>
  </head>
  <body style="margin:0">
    <div class="ql-container"><div class="ql-editor">${value}</div></div>
  </body>
  </html>`
}

export function getTime(date) {
  const Y = date.getFullYear()
  const M =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
}

/**
 * @description: 分转元
 * @param {number|string} value
 * @return {number|value}
 */
export function fen2yuan(value) {
  if (typeof value === 'number' || typeof value === 'string') {
    const result = new Decimal(value).div(new Decimal(100)).toNumber()
    return result
  } else {
    return value
  }
}

/**
 * @description: 元转分
 * @param {number|string} value
 * @return {number|value}
 */
export function yuan2fen(value) {
  if (typeof value === 'number' || typeof value === 'string') {
    const result = new Decimal(value).mul(new Decimal(100)).toNumber()
    return result
  } else {
    return value
  }
}
