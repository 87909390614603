/*
 * @Author: goodLiang
 * @Date: 2023-12-04 16:52:14
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-13 18:20:29
 * @FilePath: /vue3-template/src/router/modules/manage.js
 */
import layout from '@/layout'

export default {
  path: '/manage',
  component: layout,
  redirect: '/manage/goods/list',
  name: 'manageGoods',
  meta: { title: '买卖管理', icon: 'article' },
  children: [
    {
      path: '/manage/goods/list',
      component: () => import('@/views/manage/goods/list'),
      meta: {
        title: '商品管理',
        icon: 'article-ranking'
      }
    },
    {
      path: '/manage/goods/detail',
      component: () => import('@/views/manage/goods/detail'),
      meta: {
        title: '商品详情'
      }
    },
    {
      path: '/manage/service/list',
      component: () => import('@/views/manage/service/list'),
      meta: {
        title: '售后工作台',
        icon: 'article-ranking'
      }
    },
    {
      path: '/manage/service/detail/:id',
      component: () => import('@/views/manage/service/detail'),
      meta: {
        title: '售后工作台详情'
      }
    },
    {
      path: '/manage/sell/list',
      component: () => import('@/views/manage/sell/list'),
      meta: {
        title: '已卖出商品',
        icon: 'article-ranking'
      }
    },
    {
      path: '/manage/sell/detail',
      component: () => import('@/views/manage/sell/detail'),
      meta: {
        title: '已卖出商品详情'
      }
    }
  ]
}
