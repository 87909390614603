/*
 * @Author: goodLiang
 * @Date: 2023-09-18 16:22:34
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-09-21 10:07:11
 * @FilePath: /zhaoshang/src/store/modules/dial.js
 */

export default {
  namespaced: true,
  state: () => ({
    giftInfo: [
      {
        mobile: 13093179228,
        isGift: false,
        giftIndex: 1,
        gift: {
          name: '飞天茅台53度50ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_ft.jpg',
          code: 'GDLJHBJXJA26176B6A61'
        }
      },
      {
        mobile: 13761016929,
        isGift: false,
        giftIndex: 1,
        gift: {
          name: '飞天茅台53度50ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_ft.jpg',
          code: 'GDLJHBJXJA26176F6C62'
        }
      },
      {
        mobile: 17867157777,
        isGift: false,
        giftIndex: 5,
        gift: {
          name: '纳达尔爱诺古堡琥珀甜白葡萄酒500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_nad.jpg',
          code: 'GDLJHPTXYA316033J3T3'
        }
      },
      {
        mobile: 15816381185,
        isGift: false,
        giftIndex: 1,
        gift: {
          name: '飞天茅台53度50ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_ft.jpg',
          code: 'GDLJHBJXJA26176P6MV3'
        }
      },
      {
        mobile: 18640337797,
        isGift: false,
        giftIndex: 1,
        gift: {
          name: '飞天茅台53度50ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_ft.jpg',
          code: 'GDLJHBJXJA2617I693F6'
        }
      },
      {
        mobile: 15651385555,
        isGift: false,
        giftIndex: 5,
        gift: {
          name: '纳达尔爱诺古堡琥珀甜白葡萄酒500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_nad.jpg',
          code: 'GDLJHPTXYA316D6R6L6G'
        }
      },
      {
        mobile: 13989280351,
        isGift: false,
        giftIndex: 4,
        gift: {
          name: '泸州老窖大曲酒2003年52度500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_lz.jpg',
          code: 'GDLJHBJ03A000266U6RC'
        }
      },
      {
        mobile: 13675806440,
        isGift: false,
        giftIndex: 4,
        gift: {
          name: '泸州老窖大曲酒2003年52度500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_lz.jpg',
          code: 'GDLJHBJ03A000266L66A'
        }
      },
      {
        mobile: 13364591313,
        isGift: false,
        giftIndex: 5,
        gift: {
          name: '纳达尔爱诺古堡琥珀甜白葡萄酒500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_nad.jpg',
          code: 'GDLJHPTXYA316062F648'
        }
      },
      {
        mobile: 13726765272,
        isGift: false,
        giftIndex: 1,
        gift: {
          name: '飞天茅台53度50ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_ft.jpg',
          code: 'GDLJHBJXJA2617B66R6A'
        }
      },
      {
        mobile: 18152060077,
        isGift: false,
        giftIndex: 4,
        gift: {
          name: '泸州老窖大曲酒2003年52度500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_lz.jpg',
          code: 'GDLJHBJ03A00026FI65K'
        }
      },
      {
        mobile: 15573455555,
        isGift: false,
        giftIndex: 4,
        gift: {
          name: '泸州老窖大曲酒2003年52度500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_lz.jpg',
          code: 'GDLJHBJ03A000266W6RZ'
        }
      },
      {
        mobile: 18638789888,
        isGift: false,
        giftIndex: 1,
        gift: {
          name: '飞天茅台53度50ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_ft.jpg',
          code: 'GDLJHBJXJA26176R665N'
        }
      },
      {
        mobile: 17710821596,
        isGift: false,
        giftIndex: 2,
        gift: {
          name: '古井贡酒献礼原浆50度500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_gj.jpg',
          code: 'GDLJHBJXJA015966Q65X'
        }
      },
      {
        mobile: 13911907510,
        isGift: false,
        giftIndex: 5,
        gift: {
          name: '纳达尔爱诺古堡琥珀甜白葡萄酒500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_nad.jpg',
          code: 'GDLJHPTXYA316055E5Y5'
        }
      },
      {
        mobile: 13301290018,
        isGift: false,
        giftIndex: 3,
        gift: {
          name: '郎酒红花郎1053度500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_lj.jpg',
          code: 'GDLJHBJXJA0092A55P5Q'
        }
      },
      {
        mobile: 15267072179,
        isGift: false,
        giftIndex: 1,
        gift: {
          name: '飞天茅台53度50ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_ft.jpg',
          code: 'GDLJHBJXJA2617Z55U57'
        }
      },
      {
        mobile: 15815536978,
        isGift: false,
        giftIndex: 5,
        gift: {
          name: '纳达尔爱诺古堡琥珀甜白葡萄酒500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_nad.jpg',
          code: 'GDLJHPTXYA31605YX55Z'
        }
      },
      {
        mobile: 13301331339,
        isGift: false,
        giftIndex: 5,
        gift: {
          name: '纳达尔爱诺古堡琥珀甜白葡萄酒500ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_nad.jpg',
          code: 'GDLJHPTXYA31605X55Y9'
        }
      },
      {
        mobile: 13932072978,
        isGift: false,
        giftIndex: 1,
        gift: {
          name: '飞天茅台53度50ml',
          pic: 'https://new-gede.oss-cn-beijing.aliyuncs.com/images/activity/jp_ft.jpg',
          code: 'GDLJHBJXJA26175F5R5Z'
        }
      }
    ]
  }),
  mutations: {
    setGiftInfo(state, index) {
      console.log('setGiftInfo :>> ', state, index)
      state.giftInfo[index].isGift = true
    }
  },
  actions: {}
}
