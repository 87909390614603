<!--
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-07-15 13:47:24
 * @FilePath: /business-cms/src/App.vue
-->
<template>
  <el-config-provider :locale="zhCn">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { useStore } from 'vuex'
import { generateNewStyle, writeNewStyle } from '@/utils/theme'

import zhCn from 'element-plus/lib/locale/lang/zh-cn'

const store = useStore()

generateNewStyle(store.getters.mainColor).then((newStyleText) => {
  writeNewStyle(newStyleText)
})

document.body.removeChild(document.getElementById('init-view'))
</script>

<style lang="scss"></style>
