<template>
  <div class="sub-title">
    <div class="sub-title__name"><slot></slot></div>
    <div class="sub-title__actions"><slot name="right"></slot></div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
  &__name {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-left: 30px;
    &::before {
      content: '';
      width: 4px;
      height: 22px;
      background: #0071db;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border-radius: 0 10px 10px 0;
      overflow: hidden;
    }
  }
}
</style>
